import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";

export default class FillableDocumentFilter {

    public requestingCompanyId: number | null = null;

    public documentType: FillableDocumentType | null = null;

    public pageNumber = 1;

    public pageSize = 20;

}